import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LinkListComponent } from './link-list/link-list.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DestroyableComponent } from './destroyable/destroyable.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PipeModule } from '@alfresco/adf-core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { FlowPageComponent } from './flow-page/flow-page.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { ProcessDefinitionIconComponent } from './process-definition-icon/process-definition-icon.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUtilsModule } from '@alf-nx-workspace/shared/utils';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PropertyTextFilterComponent } from './property-text-filter/property-text-filter.component';
import { PropertyOptionFilterComponent } from './property-option-filter/property-option-filter.component';
import { PropertyDataRangeFilterComponent } from './property-data-range-filter/property-data-range-filter.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProcessInformationComponent } from './process-information/process-information.component';
import { ProcessStatusComponent } from './process-status/process-status.component';
import { ProcessPriorityComponent } from './process-priority/process-priority.component';
import { ProcessNameComponent } from './process-name/process-name.component';
import { FlowProcessListComponent } from './flow-process-list/flow-process-list.component';
import { FlowTaskListComponent } from './flow-task-list/flow-task-list.component';
import { FlowDocumentListComponent } from './flow-document-list/flow-document-list.component';
import { FlowIconComponent } from './flow-icon/flow-icon.component';
import { DialogBackdropComponent } from './dialog-backdrop/dialog-backdrop.component';
import { DocumentLinkComponent } from './document-link/document-link.component';
import { DocumentProcessInformationComponent } from './document-process-information/document-process-information.component';
import { InformationElementComponent } from './information-element/information-element.component';
import { ProcessAuditComponent } from './process-audit/process-audit.component';
import { AdDirective } from './dynamic.directive';
import { PropertyFacetFieldFilterComponent } from './property-facet-field-filter/property-facet-field-filter.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PropertyFacetFieldDefaultFilterComponent } from './property-facet-field-filter/property-facet-field-default-filter/property-facet-field-default-filter.component';
import { PropertyFacetFieldDateFilterComponent } from './property-facet-field-filter/property-facet-field-date-filter/property-facet-field-date-filter.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { DateRangeFilterFromHeaderComponent } from './date-range-filter/date-range-filter-from-header/date-range-filter-from-header.component';
import { DateRangeFilterToHeaderComponent } from './date-range-filter/date-range-filter-to-header/date-range-filter-to-header.component';
import { DialogFlowInformationComponent } from './dialog-flow-information/dialog-flow-information.component';
import { BokuFullNamePipe } from './boku-full-name.pipe';
import { TryTranslatePipe } from './try-translate.pipe';
import { ProcessApsDocumentListComponent } from './process-aps-document-list/process-aps-document-list.component';

import { ProcessTasksComponent } from './process-tasks/process-tasks.component';
import { ProcessDocumentsComponent } from './process-documents/process-documents.component';
import { TaskComponent } from './task/task.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PropertyAffectedUserFilterComponent } from './property-affected-user-filter/property-affected-user-filter.component';
import { AvatarFullnameComponent } from './avatar-fullname/avatar-fullname.component';
import { ProcessResultComponent } from './process-result/process-result.component';
import { ProcessCancelledComponent } from './process-cancelled/process-cancelled.component';
import { ListNoDataComponent } from './list-no-data/list-no-data.component';
import { ForModule } from "@rx-angular/template/for";
import { ApsDocumentLinkElementComponent } from './aps-document-link-element/aps-document-link-element.component';
import { HasRoutePermissionDirective } from "./has-route-permission.directive";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { JobComponent } from './job/job.component';
import { ProcessJobsComponent } from "./process-jobs/process-jobs.component";
import { BokuTaskNamePipe } from "./boku-task-name.pipe";

const materialModules = [
  MatSidenavModule,
  MatButtonModule,
  MatIconModule,
  MatPaginatorModule,
  MatListModule,
  MatCardModule,
  MatMenuModule,
  MatTableModule,
  MatSortModule,
  MatToolbarModule,
  MatBadgeModule,
  MatDialogModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatExpansionModule,
  MatTooltipModule,
  MatTabsModule,
  MatChipsModule,
  MatSnackBarModule,
];

@NgModule({
  imports: [
    ForModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipeModule,
    SharedUtilsModule,
    DragDropModule,
    ...materialModules,
    MatChipsModule,
    MatAutocompleteModule,
    TranslateModule,
    InformationElementComponent,
    FlowIconComponent,
    HasRoutePermissionDirective,
    ApsDocumentLinkElementComponent,
    BokuTaskNamePipe
  ],
  declarations: [
    LinkListComponent,
    DestroyableComponent,
    FlowPageComponent,
    ProcessDefinitionIconComponent,
    PropertyTextFilterComponent,
    PropertyOptionFilterComponent,
    PropertyDataRangeFilterComponent,
    PropertyFacetFieldFilterComponent,
    PropertyAffectedUserFilterComponent,
    FlowProcessListComponent,
    FlowTaskListComponent,
    FlowDocumentListComponent,
    ProcessInformationComponent,
    ProcessResultComponent,
    ProcessCancelledComponent,
    ProcessStatusComponent,
    ProcessPriorityComponent,
    ProcessNameComponent,
    DialogBackdropComponent,
    DocumentLinkComponent,
    DocumentProcessInformationComponent,
    ProcessAuditComponent,
    AdDirective,
    PropertyFacetFieldDefaultFilterComponent,
    PropertyFacetFieldDateFilterComponent,
    DateRangeFilterComponent,
    DateRangeFilterFromHeaderComponent,
    DateRangeFilterToHeaderComponent,
    DialogFlowInformationComponent,
    BokuFullNamePipe,
    ProcessApsDocumentListComponent,
    ProcessTasksComponent,
    ProcessDocumentsComponent,
    TaskComponent,
    AvatarFullnameComponent,
    ListNoDataComponent,
    TryTranslatePipe,
    JobComponent,
    ProcessJobsComponent
  ],
  exports: [
    LinkListComponent,
    FlowPageComponent,
    ProcessDefinitionIconComponent,
    PropertyTextFilterComponent,
    PropertyOptionFilterComponent,
    PropertyDataRangeFilterComponent,
    PropertyFacetFieldFilterComponent,
    PropertyAffectedUserFilterComponent,
    FlowProcessListComponent,
    FlowTaskListComponent,
    FlowDocumentListComponent,
    ProcessInformationComponent,
    ProcessResultComponent,
    ProcessCancelledComponent,
    ProcessStatusComponent,
    ProcessPriorityComponent,
    ProcessNameComponent,
    FlowIconComponent,
    DialogBackdropComponent,
    DocumentLinkComponent,
    InformationElementComponent,
    AdDirective,
    ProcessAuditComponent,
    BokuFullNamePipe,
    ProcessApsDocumentListComponent,
    ProcessTasksComponent,
    ProcessDocumentsComponent,
    TaskComponent,
    ProcessApsDocumentListComponent,
    TryTranslatePipe,
    JobComponent,
    ProcessJobsComponent,
    BokuTaskNamePipe
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {appearance: 'outline'},
    },
  ],
})
export class SharedUiModule {
  static forRoot(): ModuleWithProviders<SharedUiModule> {
    return {
      ngModule: SharedUiModule,
    };
  }
}
