import { FormFieldModel, FormModel } from '@alfresco/adf-core';
import { inject, Injectable } from '@angular/core';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';
import { BaseProcessor } from '../../base.processor';
import { DokumentenVerteilungValidator } from "../dokumenten-verteilung.validators";

@Injectable({ providedIn: 'root' })
export class DokumentenVerteilungAuftragProcessor extends BaseProcessor {
  static FORM_NAME = 'dokumenten-verteilung-auftrag';
  public readonly FORM_NAME = DokumentenVerteilungAuftragProcessor.FORM_NAME;

  readonly ebenFacade = inject(EbenFacade);

  public onLoaded(form: FormModel) {
    super.onLoaded(form);
    this.addFormFieldValidator(new DokumentenVerteilungValidator());
  }

}
