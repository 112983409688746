import { Component } from '@angular/core';
import { EbenProcessInstance } from "@alf-nx-workspace/eben/interfaces";
import { WorkflowResult, WorkflowVariables } from "@alf-nx-workspace/shared/interfaces";
import { BehaviorSubject, Subject } from "rxjs";
import { ProcessVariableUtils } from "@alf-nx-workspace/shared/utils";
import { WorkflowResultTemplate } from "@alf-nx-workspace/flow/interfaces";

@Component({
  selector: 'alf-nx-workspace-base-information-template',
  template: '',
  styleUrls: [],
  standalone: true,

})
export abstract class BaseResultTemplateComponent implements WorkflowResultTemplate {

  readonly processSubject: Subject<EbenProcessInstance> = new BehaviorSubject(null);
  readonly workflowResultSubject: Subject<string> = new BehaviorSubject(null);
  readonly variableMapSubject: Subject<Map<string, string>> = new BehaviorSubject(null);
  readonly workflowResults: typeof WorkflowResult = WorkflowResult;

  variableMap: Map<string, string>;
  workflowResult: string;

  set process(process: EbenProcessInstance) {
    this.processSubject.next(process);
    this.workflowResult = ProcessVariableUtils.getVariable(process.variables, WorkflowVariables._bokuFlow_workflowResult);
    this.workflowResultSubject.next(this.workflowResult);

    this.variableMap = ProcessVariableUtils.getProcessInstanceVariableAsMap(process);
    this.variableMapSubject.next(this.variableMap);
  }

}
