export enum DokumentenFreigabeVariables {
  '_bokuFlow_workflowResultProtocolJSON' = '_bokuFlow_workflowResultProtocolJSON',
  'reviewer' = 'reviewer',
  'reviewer1' = 'reviewer1',
  'reviewer2' = 'reviewer2',
  'reviewer3' = 'reviewer3',
  'protokoll' = 'protokoll',
  'reviewerName' = 'reviewerName',
  'begruendung' = 'begruendung',
  'draftDoc' = 'draftDoc',
  'approvedDoc' = 'approvedDoc',
  'maxCount' = 'maxCount',
  'titel' = 'titel',
  'document' = 'document'
}

export const DokumentenFreigabeVariablesSearchable = [
  DokumentenFreigabeVariables.reviewer,
  DokumentenFreigabeVariables.reviewer1,
  DokumentenFreigabeVariables.reviewer2,
  DokumentenFreigabeVariables.reviewer3,
  DokumentenFreigabeVariables.reviewerName
]
