<ng-container *ngIf="workflowResult === workflowResults.successful">
  <div class="mat-caption">{{ 'APPROVED_BY' | translate }}</div>
  <div class="mat-caption" *ngFor="let approver of protocols">
    <div>{{ approver['by'] }} {{ 'ON' | translate }} {{ approver['on'] }}</div>
  </div>
  <a [href]="getContentUrl()"
     (click)="downloadContent($event)">{{ 'LINK_TO_PDF' | translate }}</a>
</ng-container>

<ng-container *ngIf="workflowResult === workflowResults.unsuccessful">
  <ng-container *ngIf="result === 'declined'">
    <div *ngFor="let declined of protocols">
      <div class="mat-caption margin-top-4 margin-bottom-4" >{{ 'REJECTED_BY' | translate}} {{ declined['by'] }}</div>
      <div class="mat-caption">{{ 'DOKUMENTEN-FREIGABE.FINALIZE-DOCUMENT.DECLINE-REASON' | translate }}</div>
      <div class="mat-caption rejected-reason"><b>{{ declined['reason'] }}</b></div>
    </div>
  </ng-container>

  <ng-container *ngIf="result === 'autoTerminated'">
    <div *ngFor="let entry of protocols">
      <div class="mat-caption margin-top-4 margin-bottom-4" >{{ entry['result'] | translate }}</div>
      <div class="mat-caption rejected-reason"><span [innerHtml]="entry['reason'] | translateInterpolate:variableMap"></span></div>
    </div>
  </ng-container>
</ng-container>
