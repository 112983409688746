import { Component, inject } from '@angular/core';

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { RxState, selectSlice } from "@rx-angular/state";
import { ActivitiContentService } from "@alfresco/adf-core";
import { of } from "rxjs";
import { MatButtonModule } from "@angular/material/button";
import { DokumentenVerteilungVariables } from "../dokumenten-verteilung.variables";
import { fromPromise } from "rxjs/internal-compatibility";
import { switchMap } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { BaseResultTemplateComponent } from "../../base-result-template.component";
import { WorkflowApprover } from "@alf-nx-workspace/flow/interfaces";


interface ResultTemplateComponentState {
  variableMap: Map<string, string>;
  workflowResult: string,
  approved: WorkflowApprover[];
  rejectedBy: string;
  rejectedReason: string;
  contentId: number
}

@Component({
  selector: 'dokumenten-verteilung-result-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule],
  providers: [RxState]
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {

  readonly activitiContentService = inject(ActivitiContentService);
  readonly fields = DokumentenVerteilungVariables;
  readonly rxState = inject<RxState<ResultTemplateComponentState>>(RxState);
  readonly vm$ = this.rxState.select(selectSlice(["workflowResult", "approved", "rejectedBy", "rejectedReason"]));

  private filename;

  ngOnInit() {
    this.connectState();
    this.sideEffects();
  }

  private connectState() {
    this.rxState.connect("workflowResult", this.workflowResultSubject);
    this.rxState.connect("contentId", this.variableMapSubject,
      (oldState, value) => parseInt(value.get(this.fields.document))
    );
  }

  private sideEffects() { }

  public downloadContent($event: MouseEvent) {
    $event.preventDefault();

    const contentId = this.rxState.get('contentId');
    fromPromise(this.activitiContentService.contentApi.getContent(contentId))
      .pipe(
        switchMap(({ name }) => of(name)),
        switchMap((name) => {
          this.filename = name;
          return this.activitiContentService.contentApi.getRawContent(contentId);
        })
      )
      .subscribe((content) => saveAs(content, this.filename));
  }

  public getContentUrl(): string {
    return this.activitiContentService.contentApi.getRawContentUrl(this.rxState.get('contentId'));
  }
}
