import { Component, inject } from '@angular/core';

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ActivitiContentService } from "@alfresco/adf-core";
import { of } from "rxjs";
import { MatButtonModule } from "@angular/material/button";
import { DokumentenFreigabeVariables } from "../dokumenten-freigabe.variables";
import { fromPromise } from "rxjs/internal-compatibility";
import { switchMap } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { WorkflowResultProtocol } from "@alf-nx-workspace/shared/interfaces";
import { BaseResultTemplateComponent } from "../../base-result-template.component";
import { TranslateInterpolatePipe } from "@alf-nx-workspace/shared/ui";

@Component({
  selector: 'dokumenten-freigabe-result-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslateInterpolatePipe],

})
export class ResultTemplateComponent extends BaseResultTemplateComponent{

  readonly activitiContentService = inject(ActivitiContentService);
  readonly fields = DokumentenFreigabeVariables;

  public rejectedBy: string;
  public rejectedReason: string;
  public protocols: WorkflowResultProtocol[];
  public result: string;

  private contentId: number;
  private filename: string;

  ngOnInit() {

    let protocol= [];
    this.result = '';

    try {
      protocol = JSON.parse(this.variableMap.get(this.fields._bokuFlow_workflowResultProtocolJSON));
    } catch (e) {
      return;
    }

    if (protocol && !Array.isArray(protocol)) {
      return;
    }

    const declinedProtocol = protocol.filter(entry => entry.result == 'ABLEHNUNG');
    if (declinedProtocol.length) {
      this.protocols = [...declinedProtocol];
      // ToDo im Backend wird das Workflow result über einen hook gesetzt,
      //  wobei wir die begründung für die Ablehnung nicht setzen.
      this.protocols[0].reason = this.variableMap.get(this.fields.begruendung);
      this.result = 'declined';
      return;
    }

    const approved = protocol.every(entry => entry.result == 'FREIGABE');
    if (approved) {
      this.protocols = [...protocol];
      this.contentId = parseInt(this.variableMap.get(this.fields.approvedDoc));
      return;
    }

    const autoTerminatedProtocol = protocol.filter(entry => entry.result == 'WORKFLOW.AUTO_BEENDET');
    if (autoTerminatedProtocol.length) {
      this.protocols = [...autoTerminatedProtocol];
      this.result = 'autoTerminated';
      return;
    }
  }

  downloadContent($event: MouseEvent) {
    $event.preventDefault();

    fromPromise(this.activitiContentService.contentApi.getContent(this.contentId))
      .pipe(
        switchMap(({name}) => of(name)),
        switchMap((name) => {
          this.filename = name;
          return this.activitiContentService.contentApi.getRawContent(this.contentId);
        })
      )
      .subscribe((content) => saveAs(content, this.filename));
  }

  getContentUrl(): string {
    return this.activitiContentService.contentApi.getRawContentUrl(this.contentId);
  }
}
